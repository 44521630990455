import React, { useEffect, useState } from "react"
import _debounce from "lodash/debounce"
import _get from "lodash/get"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { getLamps } from "store/actions"
import confirm from "reactstrap-confirm"

import lampColumns from "./LampColumns"
import EditModal from "./components/EditModal"
import { deleteLampApi, pingApi, yellowApi } from "helpers/backend_helper"
import { toast } from "react-toastify"
import { getProductTypeByVersion } from "helpers/getProductType"

const Lamps = props => {
  const { lamps, onGetLamps, history } = props
  const [isOpenEditModal, setOpenEditModal] = useState(false)
  const [lamp, setLamp] = useState(null)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: limit,
    totalSize: 0,
    custom: true,
  })

  const [haveProductTypeLamps, setHaveProductTypeLamps] = useState([])

  const debouncedFunc = _debounce(setKeyword, 1000)

  const { SearchBar } = Search

  useEffect(() => {
    handleFetch()
  }, [page, keyword])

  useEffect(() => {
    if (!isEmpty(lamps.items)) {
      setPageOptions({
        sizePerPage: limit,
        totalSize: lamps.totalItems,
        custom: true,
      })

      const dataLamps = lamps.items.map(
        item =>
          (item = { ...item, productType: getProductTypeByVersion(item.ver) })
      )

      setHaveProductTypeLamps(dataLamps)
    }
  }, [JSON.stringify(lamps)])

  const handleFetch = () => {
    setHaveProductTypeLamps([])
    const token = localStorage.getItem("token")
    if (keyword === "") {
      onGetLamps({ limit, page, keyword }, history, token)
    } else {
      onGetLamps({ limit, page: 1, keyword }, history, token)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, data) => {
    setPage(data.page)
    if (data.searchText) {
      let searchText = data.searchText
      if (searchText.includes(":")) {
        searchText = searchText.split(":").join("")
      }
      debouncedFunc(searchText)
    }
  }

  const toggleModalEdit = data => {
    if (!isOpenEditModal) {
      setLamp(data)
      setOpenEditModal(true)
    } else {
      setLamp(null)
      setOpenEditModal(false)
    }
  }

  const handleDelete = async id => {
    let result = await confirm({
      title: "Are you sure to delete?",
      message: "It will delete forever",
    })

    if (result) {
      deleteLampApi()
        .then(res => {
          const isErrorStatus = _get(res, "data.error.status", false)
          if (!isErrorStatus) {
            toast.success("Delete Lamp Success!")
            handleFetch()
          }
        })
        .catch(err => {
          const isErrorStatus = _get(err, "data.error.status", false)
          if (isErrorStatus) {
            toast.error(err.data.error.message)
          }
        })
    }
  }

  const handlePing = mac => {
    pingApi({ mac })
      .then(res => {
        const isErrorStatus = _get(res, "data.error.status", false)
        if (!isErrorStatus) {
          toast.success("Ping Lamp Success!")
          handleFetch()
        }
      })
      .catch(err => {
        const isErrorStatus = _get(err, "data.error.status", false)
        if (isErrorStatus) {
          toast.error(err.data.error.message)
        }
      })
  }

  const handleYellow = id => {
    yellowApi({ groupId: id })
      .then(res => {
        const isErrorStatus = _get(res, "data.error.status", false)
        if (!isErrorStatus) {
          toast.success("Set yellow Lamp Success!")
          handleFetch()
        }
      })
      .catch(err => {
        const isErrorStatus = _get(err, "data.error.status", false)
        if (isErrorStatus) {
          toast.error(err.data.error.message)
        }
      })
  }

  return (
    <React.Fragment>
      <EditModal
        isOpen={isOpenEditModal}
        toggle={toggleModalEdit}
        handleFetch={handleFetch}
        lamp={lamp}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                {
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={haveProductTypeLamps || []}
                          columns={lampColumns(
                            toggleModalEdit,
                            handlePing,
                            handleYellow
                          )}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-right">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      style={{
                                        background: "#ff2f92",
                                        borderColor: "#ff2f92",
                                      }}
                                      onClick={() => handleFetch()}
                                    >
                                      Refresh
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  lamps: state.lamp.lamps,
})

const mapDispatchToProps = dispatch => ({
  onGetLamps: (data, history, token) =>
    dispatch(getLamps(data, history, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lamps))
